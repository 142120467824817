
.announcement {
  border-bottom: 1px solid var(--border);
  border-radius: 0;
  padding: var(--status-margin);

  .heading,
  .body {
    margin-bottom: var(--status-margin);
  }

  .footer {
    display: flex;
    flex-direction: column;

    .times {
      display: flex;
      flex-direction: column;
    }
  }

  .footer .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .btn {
      flex: 1;
      margin: 1em;
      max-width: 10em;
    }
  }
}
