/* stylelint-disable no-descending-specificity */
.panel {
  --__panel-background: var(--background);
  --__panel-backdrop-filter: var(--backdrop-filter);

  .tab-switcher .tabs {
    background: var(--__panel-background);
    backdrop-filter: var(--__panel-backdrop-filter);
  }

  position: relative;
  display: flex;
  flex-direction: column;

  .panel-heading {
    background-color: inherit;
  }

  &::after,
  & {
    border-radius: var(--roundness);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    box-shadow: var(--shadow);
    pointer-events: none;
  }
}

.panel-body {
  padding: var(--panel-body-padding, 0);
  background: var(--background);
  backdrop-filter: var(--__panel-backdrop-filter);

  .tab-switcher .tabs {
    background: none;
    backdrop-filter: none;
  }

  &:empty::before {
    content: "¯\\_(ツ)_/¯"; // Could use words but it'd require translations
    display: block;
    padding: 1em;
    text-align: center;
  }

  > p {
    line-height: 1.3;
    padding: 1em;
    margin: 0;
  }
}

.panel-heading,
.panel-footer {
  --panel-heading-height-padding: calc(var(--panel-header-height) * 0.2);
  --__panel-heading-gap: calc(var(--panel-header-height) * 0.1565);
  --__panel-heading-height: var(--panel-header-height);
  --__panel-heading-height-inner: calc(var(--__panel-heading-height) - 2 * var(--panel-heading-height-padding, 0));

  font-size: calc(var(--panelHeaderSize) / 3.2);
  backdrop-filter: var(--__panel-backdrop-filter);
  position: relative;
  box-sizing: border-box;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(50%, 1fr);
  grid-auto-columns: auto;
  grid-column-gap: var(--__panel-heading-gap);
  flex: none;
  background-size: cover;
  padding: var(--panel-heading-height-padding);
  height: var(--__panel-heading-height);
  line-height: var(--__panel-heading-height-inner);
  z-index: 4;

  &.-flexible-height {
    --__panel-heading-height: auto;

    &::after,
    &::before {
      display: none;
    }
  }

  &.-stub {
    &,
    &::after {
      border-radius: var(--roundness);
    }
  }

  &.-sticky {
    position: sticky;
    top: var(--navbar-height);
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
  }

  .title {
    font-size: 1.3em;
  }

  .alert {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  &:not(.-flexible-height) {
    > .button-default,
    > .alert {
      height: var(--__panel-heading-height-inner);
      min-height: 0;
      box-sizing: border-box;
      margin: 0;
      min-width: 1px;
      padding-top: 0;
      padding-bottom: 0;
      align-self: stretch;
    }

    > .alert {
      line-height: calc(var(--__panel-heading-height-inner) - 2px);
    }
  }
}

// TODO Should refactor panels into separate component and utilize slots

.panel-heading {
  border-radius: var(--roundness) var(--roundness) 0 0;
  border-width: 0 0 1px;
  align-items: start;
  background-image:
    linear-gradient(to bottom, var(--background), var(--background)),
    linear-gradient(to bottom, var(--__panel-background), var(--__panel-background));

  &::after {
    background-color: var(--background);
    z-index: -2;
    border-radius: var(--roundness) var(--roundness) 0 0;
    box-shadow: var(--shadow);
  }

  &:not(.-flexible-height) {
    > .button-default {
      flex-shrink: 0;
    }
  }

  .rightside-button {
    align-self: stretch;
    text-align: center;
    width: var(--__panel-heading-height);
    height: var(--__panel-heading-height);
    margin: calc(-1 * var(--panel-heading-height-padding)) 0;
    margin-right: calc(-1 * var(--__panel-heading-gap));

    > button {
      box-sizing: border-box;
      padding: calc(1 * var(--panel-heading-height-padding)) 0;
      height: 100%;
      width: 100%;
      text-align: center;

      svg {
        font-size: 1.2em;
      }
    }
  }

  .rightside-icon {
    align-self: stretch;
    text-align: center;
    width: var(--__panel-heading-height);
    margin-right: calc(-1 * var(--__panel-heading-gap));

    svg {
      font-size: 1.2em;
    }
  }
}

.panel-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  align-items: center;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: var(--border);
  background-color: var(--__panel-background);
}
/* stylelint-enable no-descending-specificity */
