
.side-drawer-container {
  position: fixed;
  z-index: var(--ZI_navbar);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  transition-duration: 0s;
  transition-property: transform;
}

.side-drawer-container-open {
  transform: translate(0%);
}

.side-drawer-container-closed {
  transition-delay: 0.35s;
  transform: translate(-100%);
}

.side-drawer-darken {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  transition: 0.35s;
  transition-property: background-color;
  background-color: rgb(0 0 0 / 50%);
}

.side-drawer-darken-closed {
  background-color: rgb(0 0 0 / 0%);
}

.side-drawer-click-outside {
  flex: 1 1 100%;
}

.side-drawer {
  overflow-x: hidden;
  transition: 0.35s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-property: transform;
  margin: 0 0 0 -100px;
  padding: 0 0 1em 100px;
  width: 80%;
  max-width: 20em;
  flex: 0 0 80%;
  box-shadow: var(--shadow);
  background-color: var(--background);

  .badge {
    margin-left: 10px;
  }
}

.side-drawer-logo-wrapper {
  display: flex;
  align-items: center;
  padding: 0.85em;

  img {
    flex: none;
    height: 50px;
    margin-right: 0.85em;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.side-drawer-click-outside-closed {
  flex: 0 0 0;
}

.side-drawer-closed {
  transform: translate(-100%);
}

.side-drawer-heading {
  background: transparent;
  flex-direction: column;
  align-items: stretch;
  display: flex;
  padding: 0;
  margin: 0;
}

.side-drawer ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid;
  border-color: var(--border);
}

.side-drawer ul:last-child {
  border: 0;
}

.side-drawer li {
  padding: 0;

  a,
  button {
    box-sizing: border-box;
    display: block;
    height: 3em;
    line-height: 3em;
    padding: 0 0.7em;
  }
}
