.chat-list-item {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;

  :focus {
    outline: none;
  }

  .chat-list-item-left {
    margin-right: 1em;
  }

  .chat-list-item-center {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    word-wrap: break-word;
  }

  .heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    line-height: 1em;
  }

  .heading-right {
    white-space: nowrap;
  }

  .name-and-account-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex-shrink: 1;
    line-height: var(--post-line-height);
  }

  .chat-preview {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0.35em 0;
    color: var(--textFaint);
    width: 100%;
  }

  a {
    color: var(--linkFaint);
    text-decoration: none;
    pointer-events: none;
  }

  &:hover .animated.avatar {
    canvas {
      display: none;
    }

    img {
      visibility: visible;
    }
  }

  .chat-preview-body {
    --emoji-size: 1.4em;

    padding-right: 1em;
  }

  .time-wrapper {
    line-height: var(--post-line-height);
  }
}
