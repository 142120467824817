
.still-image {
  position: relative;
  line-height: 0;
  overflow: hidden;
  display: inline-flex;
  align-items: center;

  canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    visibility: var(--_still-image-canvas-visibility, visible);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.animated {
    &::before {
      zoom: var(--_still_image-label-scale, 1);
      content: "gif";
      position: absolute;
      line-height: 1;
      font-size: 0.7em;
      top: 0.5em;
      left: 0.5em;
      background: rgb(127 127 127 / 50%);
      color: #fff;
      display: block;
      padding: 2px 4px;
      border-radius: var(--roundness);
      z-index: 2;
      visibility: var(--_still-image-label-visibility, visible);
    }

    &:hover canvas {
      display: none;
    }

    &:hover::before {
      visibility: var(--_still-image-label-visibility, hidden);
    }

    img {
      visibility: var(--_still-image-img-visibility, hidden);
    }

    &:hover img {
      visibility: visible;
    }
  }
}
