.chat-message-wrapper {
  &.hovered-message-chain {
    .animated.Avatar {
      canvas {
        display: none;
      }

      img {
        visibility: visible;
      }
    }
  }

  .chat-message-menu {
    transition: opacity 0.1s;
    opacity: 0;
    position: absolute;
    top: -0.8em;

    button {
      padding-top: 0.2em;
      padding-bottom: 0.2em;
    }
  }

  .menu-icon {
    cursor: pointer;
  }

  .popover {
    width: 12em;
  }

  .chat-message {
    display: flex;
    padding-bottom: 0.5em;

    .status-body:hover {
      --_still-image-img-visibility: visible;
      --_still-image-canvas-visibility: hidden;
      --_still-image-label-visibility: hidden;
    }
  }

  .avatar-wrapper {
    margin-right: 0.72em;
    width: 32px;
  }

  .link-preview,
  .attachments {
    margin-bottom: 1em;
  }

  .status {
    background-color: var(--background);
    color: var(--text);
    border-radius: var(--roundness);
    display: flex;
    padding: 0.75em;
    border: 1px solid var(--border);
  }

  .created-at {
    position: relative;
    float: right;
    font-size: 0.8em;
    margin: -1em 0 -0.5em;
    font-style: italic;
    opacity: 0.8;
  }

  .without-attachment {
    .message-content {
      // TODO figure out how to do it properly
      .RichContent::after {
        margin-right: 5.4em;
        content: " ";
        display: inline-block;
      }
    }
  }

  .pending {
    .status-content.media-body,
    .created-at {
      color: var(--faint);
    }
  }

  .error {
    .status-content.media-body,
    .created-at {
      color: var(--badgeNotification);
    }
  }

  .chat-message-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 80%;
    min-width: 10em;
    width: 100%;
  }

  .outgoing {
    display: flex;
    flex-flow: row wrap;
    align-content: end;
    justify-content: flex-end;

    .chat-message-inner {
      align-items: flex-end;
    }

    .chat-message-menu {
      right: 0.4rem;
    }
  }

  .incoming {
    .chat-message-menu {
      left: 0.4rem;
    }
  }

  .chat-message-inner.with-media {
    width: 100%;

    .status {
      width: 100%;
    }
  }

  .visible {
    opacity: 1;
  }
}

.chat-message-date-separator {
  text-align: center;
  margin: 1.4em 0;
  font-size: 0.9em;
  user-select: none;
  color: var(--textFaint);
}
