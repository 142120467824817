// TODO Copypaste from Status, should unify it somehow
.Notification {
  border-bottom: 1px solid;
  border-color: var(--border);
  word-wrap: break-word;
  word-break: break-word;

  &.Status {
    /* stylelint-disable-next-line declaration-no-important */
    background-color: transparent !important;
  }

  --emoji-size: 14px;

  &:hover {
    --_still-image-img-visibility: visible;
    --_still-image-canvas-visibility: hidden;
    --_still-image-label-visibility: hidden;
  }

  &.-muted {
    padding: 0.25em 0.6em;
    height: 1.2em;
    line-height: 1.2em;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;

    & .status-username,
    & .mute-thread,
    & .mute-words {
      word-wrap: normal;
      word-break: normal;
      white-space: nowrap;
    }

    & .status-username,
    & .mute-words {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .status-username {
      font-weight: normal;
      flex: 0 1 auto;
      margin-right: 0.2em;
      font-size: smaller;
    }

    .mute-thread {
      flex: 0 0 auto;
    }

    .mute-words {
      flex: 1 0 5em;
      margin-left: 0.2em;

      &::before {
        content: " ";
      }
    }

    .unmute {
      flex: 0 0 auto;
      margin-left: auto;
      display: block;
    }
  }

  .type-icon {
    margin: 0 0.1em;
  }

  &.-type--repeat .type-icon {
    color: var(--cGreen);
  }

  &.-type--follow .type-icon {
    color: var(--cBlue);
  }

  &.-type--follow-request .type-icon {
    color: var(--cBlue);
  }

  &.-type--like .type-icon {
    color: var(--cOrange);
  }

  &.-type--move .type-icon {
    color: var(--cBlue);
  }
}
