
.media-upload {
  .hidden-input-file {
    display: none;
  }
}

label.media-upload {
  cursor: pointer; // We use <label> for interactivity... i wonder if it's fine
}
