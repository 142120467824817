
.NavPanel {
  .panel {
    overflow: hidden;
    box-shadow: var(--shadow);
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .navigation-chevron {
    margin-left: 0.8em;
    margin-right: 0.8em;
    font-size: 1.1em;
  }

  .timelines-chevron {
    margin-left: 0.8em;
    font-size: 1.1em;
  }

  .timelines-background {
    padding: 0 0 0 0.6em;
  }

  .nav-panel-heading {
    // breaks without a unit
    // stylelint-disable-next-line length-zero-no-unit
    --panel-heading-height-padding: 0px;
  }
}
