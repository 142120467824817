// stylelint-disable rscss/class-format
/* stylelint-disable no-descending-specificity */
@import "./panel";

:root {
  --status-margin: 0.75em;
  --post-line-height: 1.4;
  // Z-Index stuff
  --ZI_media_modal: 9000;
  --ZI_modals_popovers: 8500;
  --ZI_modals: 8000;
  --ZI_navbar_popovers: 7500;
  --ZI_navbar: 7000;
  --ZI_popovers: 6000;

  // Fallback for when stuff is loading
  --background: var(--bg);
}

html {
  font-size: var(--textSize, 14px);

  --navbar-height: var(--navbarSize, 3.5rem);
  --emoji-size: var(--emojiSize, 32px);
  --panel-header-height: var(--panelHeaderSize, 3.2rem);
  // overflow-x: clip causes my browser's tab to crash with SIGILL lul
}

body {
  font-family: sans-serif;
  font-family: var(--font);
  margin: 0;
  color: var(--text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  overflow-x: clip;
  overflow-y: scroll;

  &.hidden {
    display: none;
  }
}

// ## Custom scrollbars
// Only show custom scrollbars on devices which
// have a cursor/pointer to operate them
@media (any-pointer: fine) {
  * {
    scrollbar-color: var(--fg) transparent;

    &::-webkit-scrollbar {
      background: transparent;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }

    &::-webkit-resizer {
      /* stylelint-disable-next-line declaration-no-important */
      background-color: transparent !important;
      background-image:
        linear-gradient(
          135deg,
          transparent calc(50% - 1px),
          var(--textFaint) 50%,
          transparent calc(50% + 1px),
          transparent calc(75% - 1px),
          var(--textFaint) 75%,
          transparent calc(75% + 1px),
        );
    }

    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-thumb {
      box-shadow: var(--shadow);
      border-radius: var(--roundness);
    }

    // horizontal/vertical/increment/decrement are webkit-specific stuff
    // that indicates whether we're affecting vertical scrollbar, increase button etc
    // stylelint-disable selector-pseudo-class-no-unknown
    &::-webkit-scrollbar-button {
      --___bgPadding: 2px;

      color: var(--text);
      background-repeat: no-repeat, no-repeat;

      &:horizontal {
        background-size: 50% calc(50% - var(--___bgPadding)), 50% calc(50% - var(--___bgPadding));

        &:increment {
          background-image:
            linear-gradient(45deg, var(--text) 50%, transparent 51%),
            linear-gradient(-45deg, transparent 50%, var(--text) 51%);
          background-position: top var(--___bgPadding) left 50%, right 50% bottom var(--___bgPadding);
        }

        &:decrement {
          background-image:
            linear-gradient(45deg, transparent 50%, var(--text) calc(50% + 1px)),
            linear-gradient(-45deg, var(--text) 50%, transparent 51%);
          background-position: bottom var(--___bgPadding) right 50%, left 50% top var(--___bgPadding);
        }
      }

      &:vertical {
        background-size: calc(50% - var(--___bgPadding)) 50%, calc(50% - var(--___bgPadding)) 50%;

        &:increment {
          background-image:
            linear-gradient(-45deg, transparent 50%, var(--text) 51%),
            linear-gradient(45deg, transparent 50%, var(--text) 51%);
          background-position: right var(--___bgPadding) top 50%, left var(--___bgPadding) top 50%;
        }

        &:decrement {
          background-image:
            linear-gradient(-45deg, var(--text) 50%, transparent 51%),
            linear-gradient(45deg, var(--text) 50%, transparent 51%);
          background-position: left var(--___bgPadding) top 50%, right var(--___bgPadding) top 50%;
        }
      }
    }
    // stylelint-enable selector-pseudo-class-no-unknown
  }
  // Body should have background to scrollbar otherwise it will use white (body color?)
  html {
    scrollbar-color: var(--fg) var(--wallpaper);
    background: var(--wallpaper);
  }
}

a {
  text-decoration: none;
  color: var(--link);
}

h4 {
  margin: 0;
}

.iconLetter {
  display: inline-block;
  text-align: center;
  font-weight: 1000;
}

i[class*="icon-"],
.svg-inline--fa,
.iconLetter {
  color: var(--icon);
}

nav {
  z-index: var(--ZI_navbar);
  box-shadow: var(--shadow);
  box-sizing: border-box;
  height: var(--navbar-height);
  font-size: calc(var(--navbar-height) / 3.5);
  position: fixed;
}

#sidebar {
  grid-area: sidebar;
}

#modal {
  position: absolute;
  z-index: var(--ZI_modals);
}

.column.-scrollable {
  top: var(--navbar-height);
  position: sticky;
}

#main-scroller {
  grid-area: content;
  position: relative;
}

#notifs-column {
  grid-area: notifs;
}

.app-bg-wrapper {
  position: fixed;
  height: 100%;
  top: var(--navbar-height);
  z-index: -1000;
  left: 0;
  right: -20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--wallpaper);
  background-image: var(--body-background-image);
  background-position: 50%;
}

.underlay {
  grid-column: 1 / span 3;
  grid-row: 1 / 1;
  pointer-events: none;
  background-color: var(--underlay);
  z-index: -1000;
}

.app-layout {
  --miniColumn: 25rem;
  --maxiColumn: 45rem;
  --columnGap: 1rem;
  --effectiveSidebarColumnWidth: minmax(var(--miniColumn), var(--sidebarColumnWidth, var(--miniColumn)));
  --effectiveNotifsColumnWidth: minmax(var(--miniColumn), var(--notifsColumnWidth, var(--miniColumn)));
  --effectiveContentColumnWidth: minmax(var(--miniColumn), var(--contentColumnWidth, var(--maxiColumn)));

  position: relative;
  display: grid;
  grid-template-columns:
    var(--effectiveSidebarColumnWidth)
    var(--effectiveContentColumnWidth);
  grid-template-areas: "sidebar content";
  grid-template-rows: 1fr;
  box-sizing: border-box;
  margin: 0 auto;
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 100vh;
  overflow-x: clip;

  .column {
    --___columnMargin: var(--columnGap);

    display: grid;
    grid-template-columns: 100%;
    box-sizing: border-box;
    grid-row: 1 / 1;
    margin: 0 calc(var(--___columnMargin) / 2);
    padding: calc(var(--___columnMargin)) 0;
    row-gap: var(--___columnMargin);
    align-content: start;

    &:not(.-scrollable) {
      margin-top: var(--navbar-height);
    }

    &:hover {
      z-index: 2;
    }

    &.-full-height {
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    &.-scrollable {
      --___paddingIncrease: calc(var(--columnGap) / 2);

      position: sticky;
      top: var(--navbar-height);
      max-height: calc(100vh - var(--navbar-height));
      overflow-y: auto;
      overflow-x: hidden;
      margin-left: calc(var(--___paddingIncrease) * -1);
      padding-left: calc(var(--___paddingIncrease) + var(--___columnMargin) / 2);

      // On browsers that don't support hiding scrollbars we enforce "show scrolbars" mode
      // might implement old style of hiding scrollbars later if there's demand
      @supports (scrollbar-width: none) or (-webkit-text-fill-color: initial) {
        &:not(.-show-scrollbar) {
          scrollbar-width: none;
          margin-right: calc(var(--___paddingIncrease) * -1);
          padding-right: calc(var(--___paddingIncrease) + var(--___columnMargin) / 2);

          &::-webkit-scrollbar {
            display: block;
            width: 0;
          }
        }
      }

      .panel-heading.-sticky {
        top: calc(var(--columnGap) / -1);
      }
    }
  }

  &.-has-new-post-button {
    .column {
      padding-bottom: 10rem;
    }
  }

  &.-no-sticky-headers {
    .column {
      .panel-heading.-sticky {
        position: relative;
        top: 0;
      }
    }
  }

  .column-inner {
    display: grid;
    grid-template-columns: 100%;
    box-sizing: border-box;
    row-gap: 1em;
    align-content: start;
  }

  &.-reverse:not(.-wide, .-mobile) {
    grid-template-columns:
      var(--effectiveContentColumnWidth)
      var(--effectiveSidebarColumnWidth);
    grid-template-areas: "content sidebar";
  }

  &.-wide {
    grid-template-columns:
      var(--effectiveSidebarColumnWidth)
      var(--effectiveContentColumnWidth)
      var(--effectiveNotifsColumnWidth);
    grid-template-areas: "sidebar content notifs";

    &.-reverse {
      grid-template-columns:
        var(--effectiveNotifsColumnWidth)
        var(--effectiveContentColumnWidth)
        var(--effectiveSidebarColumnWidth);
      grid-template-areas: "notifs content sidebar";
    }
  }

  &.-mobile {
    grid-template-columns: 100vw;
    grid-template-areas: "content";
    padding: 0;

    .column {
      padding-top: 0;
      margin: var(--navbar-height) 0 0 0;
    }

    .panel-heading,
    .panel-heading::after,
    .panel-heading::before,
    .panel,
    .panel::after {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    #sidebar,
    #notifs-column {
      display: none;
    }
  }

  &.-normal {
    #notifs-column {
      display: none;
    }
  }
}

.text-center {
  text-align: center;
}

.button-default {
  user-select: none;
  color: var(--text);
  border: none;
  cursor: pointer;
  background-color: var(--background);
  box-shadow: var(--shadow);
  font-size: 1em;
  font-family: sans-serif;
  font-family: var(--font);

  &::-moz-focus-inner {
    border: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.menu-item,
.list-item {
  display: block;
  box-sizing: border-box;
  border: none;
  outline: none;
  text-align: initial;
  font-size: inherit;
  font-family: inherit;
  font-weight: 400;
  cursor: pointer;
  color: inherit;
  clear: both;
  position: relative;
  white-space: nowrap;
  border-color: var(--border);
  border-style: solid;
  border-width: 0;
  border-top-width: 1px;
  width: 100%;
  line-height: var(--__line-height);
  padding: var(--__vertical-gap) var(--__horizontal-gap);
  background: transparent;

  --__line-height: 1.5em;
  --__horizontal-gap: 0.75em;
  --__vertical-gap: 0.5em;

  &.-non-interactive {
    cursor: auto;
  }

  &.-active,
  &:hover {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  &.-active + &,
  &:hover + & {
    border-top-width: 0;
  }

  &:hover + .menu-item-collapsible:not(.-expanded) + &,
  &.-active + .menu-item-collapsible:not(.-expanded) + & {
    border-top-width: 0;
  }

  &[aria-expanded="true"] {
    border-bottom-width: 1px;
  }

  a,
  button:not(.button-default) {
    text-align: initial;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    display: inline;
    font-size: 100%;
    font-family: inherit;
    line-height: unset;
    color: var(--text);
  }

  &:first-child {
    border-top-right-radius: var(--roundness);
    border-top-left-radius: var(--roundness);
    border-top-width: 0;
  }

  &:last-child {
    border-bottom-right-radius: var(--roundness);
    border-bottom-left-radius: var(--roundness);
    border-bottom-width: 0;
  }
}

.button-unstyled {
  border: none;
  outline: none;
  display: inline;
  text-align: initial;
  font-size: 100%;
  font-family: inherit;
  box-shadow: var(--shadow);
  background-color: transparent;
  padding: 0;
  line-height: unset;
  cursor: pointer;
  box-sizing: content-box;
  color: inherit;

  &.-link {
    /* stylelint-disable-next-line declaration-no-important */
    color: var(--link) !important;
  }
}

input,
textarea {
  border: none;
  display: inline-block;
  outline: none;
}

.input {
  &.unstyled {
    border-radius: 0;
    /* stylelint-disable-next-line declaration-no-important */
    background: none !important;
    box-shadow: none;
    height: unset;
  }

  --_padding: 0.5em;

  border: none;
  background-color: var(--background);
  color: var(--text);
  box-shadow: var(--shadow);
  font-family: var(--font);
  font-size: 1em;
  margin: 0;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  line-height: 2;
  hyphens: none;
  padding: 0 var(--_padding);

  &:disabled,
  &[disabled="disabled"],
  &.disabled {
    cursor: not-allowed;
  }

  &[type="range"] {
    background: none;
    border: none;
    margin: 0;
    box-shadow: none;
    flex: 1;
  }

  &[type="radio"] {
    display: none;

    &:checked + label::before {
      box-shadow: var(--shadow);
      background-color: var(--background);
      color: var(--text);
    }

    &:disabled {
      &,
      & + label,
      & + label::before {
        opacity: 0.5;
      }
    }

    + label::before {
      flex-shrink: 0;
      display: inline-block;
      content: "•";
      transition: box-shadow 200ms;
      width: 1.1em;
      height: 1.1em;
      border-radius: 100%; // Radio buttons should always be circle
      background-color: var(--background);
      box-shadow: var(--shadow);
      margin-right: 0.5em;
      vertical-align: top;
      text-align: center;
      line-height: 1.1;
      font-size: 1.1em;
      box-sizing: border-box;
      color: transparent;
      overflow: hidden;
    }
  }

  &[type="checkbox"] {
    &:checked + label::before {
      color: var(--text);
      background-color: var(--background);
      box-shadow: var(--shadow);
    }

    &:disabled {
      &,
      & + label,
      & + label::before {
        opacity: 0.5;
      }
    }

    + label::before {
      flex-shrink: 0;
      display: inline-block;
      content: "✓";
      transition: color 200ms;
      width: 1.1em;
      height: 1.1em;
      border-radius: var(--roundness);
      box-shadow: var(--shadow);
      margin-right: 0.5em;
      vertical-align: top;
      text-align: center;
      line-height: 1.1;
      font-size: 1.1em;
      box-sizing: border-box;
      color: transparent;
      overflow: hidden;
    }
  }

  &.resize-height {
    resize: vertical;
  }
}

.input,
.button-default {
  --_roundness-left: var(--roundness);
  --_roundness-right: var(--roundness);

  border-top-left-radius: var(--_roundness-left);
  border-bottom-left-radius: var(--_roundness-left);
  border-top-right-radius: var(--_roundness-right);
  border-bottom-right-radius: var(--_roundness-right);
}

// Textareas should have stock line-height + vertical padding instead of huge line-height
textarea.input {
  padding: var(--_padding);
  line-height: var(--post-line-height);
}

option {
  color: var(--text);
  background-color: var(--background);
}

.hide-number-spinner {
  appearance: textfield;

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    opacity: 0;
    display: none;
  }
}

.cards-list {
  list-style: none;
  display: grid;
  grid-auto-flow: row dense;
  grid-template-columns: 1fr 1fr;

  li {
    border: 1px solid var(--border);
    border-radius: var(--roundness);
    padding: 0.5em;
    margin: 0.25em;
  }
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  > *,
  > * .button-default {
    --_roundness-left: 0;
    --_roundness-right: 0;

    position: relative;
    flex: 1 1 auto;
  }

  > *:first-child,
  > *:first-child .button-default {
    --_roundness-left: var(--roundness);
  }

  > *:last-child,
  > *:last-child .button-default {
    --_roundness-right: var(--roundness);
  }
}

.fa {
  color: grey;
}

.mobile-shown {
  display: none;
}

.badge {
  box-sizing: border-box;
  display: inline-block;
  border-radius: 99px;
  max-width: 10em;
  min-width: 1.7em;
  height: 1.3em;
  padding: 0.15em;
  vertical-align: middle;
  font-weight: normal;
  font-style: normal;
  font-size: 0.9em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.-dot,
  &.-counter {
    margin: 0;
    position: absolute;
  }

  &.-dot {
    min-height: 8px;
    max-height: 8px;
    min-width: 8px;
    max-width: 8px;
    padding: 0;
    line-height: 0;
    font-size: 0;
    left: calc(50% - 4px);
    top: calc(50% - 4px);
    margin-left: 6px;
    margin-top: -6px;
  }

  &.-counter {
    border-radius: var(--roundness);
    font-size: 0.75em;
    line-height: 1;
    text-align: right;
    padding: 0.2em;
    min-width: 0;
    left: calc(50% - 0.5em);
    top: calc(50% - 0.4em);
    margin-left: 0.7em;
    margin-top: -1em;
  }
}

.alert {
  margin: 0 0.35em;
  padding: 0 0.25em;
  border-radius: var(--roundness);
  border: 1px solid var(--border);
}

.faint {
  --text: var(--textFaint);
  --link: var(--linkFaint);

  color: var(--text);
}

.visibility-notice {
  padding: 0.5em;
  border: 1px solid var(--textFaint);
  border-radius: var(--roundness);
}

.notice-dismissible {
  padding-right: 4rem;
  position: relative;

  .dismiss {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5em;
    color: inherit;
  }
}

.fa-scale-110 {
  &.svg-inline--fa,
  &.iconLetter {
    font-size: 1.1em;
  }

  &.svg-inline--fa {
    vertical-align: -0.15em;
  }
}

.fa-old-padding {
  &.iconLetter,
  &.svg-inline--fa,
  &-layer {
    padding: 0 0.3em;
  }
}

.veryfaint {
  opacity: 0.25;
}

.timeago {
  --link: var(--text);
  --linkFaint: var(--textFaint);
}

.login-hint {
  text-align: center;

  @media all and (min-width: 801px) {
    display: none;
  }

  a {
    display: inline-block;
    padding: 1em 0;
    width: 100%;
  }
}

.btn.button-default {
  min-height: 2em;
}

.new-status-notification {
  position: relative;
  font-size: 1.1em;
  z-index: 1;
  flex: 1;
}

@media all and (max-width: 800px) {
  .mobile-hidden {
    display: none;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes shakeError {
  0% {
    transform: translateX(0);
  }

  15% {
    transform: translateX(0.375rem);
  }

  30% {
    transform: translateX(-0.375rem);
  }

  45% {
    transform: translateX(0.375rem);
  }

  60% {
    transform: translateX(-0.375rem);
  }

  75% {
    transform: translateX(0.375rem);
  }

  90% {
    transform: translateX(-0.375rem);
  }

  100% {
    transform: translateX(0);
  }
}

// Vue transitions
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
/* stylelint-enable no-descending-specificity */

.visible-for-screenreader-only {
  display: block;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  visibility: visible;
  clip: rect(0 0 0 0);
  padding: 0;
  position: absolute;
}

*::selection {
  color: var(--selectionText);
  background-color: var(--selectionBackground);
}
