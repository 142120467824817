
.search-result-heading {
  color: var(--faint);
  padding: 0.75rem;
  text-align: center;
}

@media all and (max-width: 800px) {
  .search-nav-heading {
    .tab-switcher .tabs .tab-wrapper {
      display: block;
      justify-content: center;
      flex: 1 1 auto;
      text-align: center;
    }
  }
}

.search-result {
  box-sizing: border-box;
  border-bottom: 1px solid;
  border-color: var(--border);
}

.search-input-container {
  padding: 0.8rem;
  display: flex;
  justify-content: center;

  .search-input {
    width: 100%;
    line-height: 1.125rem;
    font-size: 1rem;
    padding: 0.5rem;
    box-sizing: border-box;
  }

  .search-button {
    margin-left: 0.5em;
  }
}

.loading-icon {
  padding: 1em;
}

.trend {
  display: flex;
  align-items: center;

  .hashtag {
    flex: 1 1 auto;
    color: var(--text);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .count {
    flex: 0 0 auto;
    width: 2rem;
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 500;
    text-align: center;
    color: var(--text);
  }
}

.more-statuses-button {
  height: 3.5em;
  line-height: 3.5em;
  width: 100%;
}

