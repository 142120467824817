
.modal-view.edit-form-modal-view {
  align-items: flex-start;
}

.edit-form-modal-panel {
  flex-shrink: 0;
  margin-top: 25%;
  margin-bottom: 2em;
  width: 100%;
  max-width: 700px;

  @media (orientation: landscape) {
    margin-top: 8%;
  }

  .form-bottom-left {
    max-width: 6.5em;

    .emoji-icon {
      justify-content: right;
    }
  }
}
