
.announcements-page {
  .post-form {
    padding: var(--status-margin);

    .heading,
    .body {
      margin-bottom: var(--status-margin);
    }

    .post-button {
      min-width: 10em;
    }
  }
}
