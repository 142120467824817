
.staff-group {
  padding-left: 1em;
  padding-top: 1em;

  .basic-user-card {
    padding-left: 0;
  }
}

