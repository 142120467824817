
.thread-tree-replies {
  margin-left: var(--status-margin);
  border-left: 2px solid var(--border);
}

.thread-tree-replies-hidden {
  padding: var(--status-margin);

  /* Make the button stretch along the whole row */
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
