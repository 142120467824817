
.floating-shout {
  position: fixed;
  bottom: 0.5em;
  z-index: var(--ZI_popovers);
  max-width: 25em;

  &.-left {
    left: 0.5em;
  }

  &:not(.-left) {
    right: 0.5em;
  }
}

.shout-panel {
  .shout-heading {
    cursor: pointer;

    .icon {
      color: var(--text);
      margin-right: 0.5em;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .shout-window {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 20em;
  }

  .shout-window-container {
    height: 100%;
  }

  .shout-message {
    display: flex;
    padding: 0.2em 0.5em;
  }

  .shout-avatar {
    img {
      height: 24px;
      width: 24px;
      border-radius: var(--roundness);
      margin-right: 0.5em;
      margin-top: 0.25em;
    }
  }

  .shout-input {
    display: flex;

    textarea {
      flex: 1;
      margin: 0.6em;
      min-height: 3.5em;
      resize: none;
    }
  }

  .shout-panel {
    .title {
      display: flex;
      justify-content: space-between;
    }
  }
}
