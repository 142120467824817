.RichContent {
  font-family: var(--font);

  &.-faint {
    /* stylelint-disable declaration-no-important */
    --text: var(--textFaint) !important;
    --link: var(--linkFaint) !important;
    --funtextGreentext: var(--funtextGreentextFaint) !important;
    --funtextCyantext: var(--funtextCyantextFaint) !important;
    /* stylelint-enable declaration-no-important */
  }

  blockquote {
    margin: 0.2em 0 0.2em 0.2em;
    font-style: italic;
    border-left: 0.2em solid var(--textFaint);
    padding-left: 1em;
  }

  pre {
    overflow: auto;
  }

  code,
  samp,
  kbd,
  var,
  pre {
    font-family: var(--monoFont);
  }

  p {
    margin: 0 0 1em;
  }

  p:last-child {
    margin: 0;
  }

  h1 {
    font-size: 1.1em;
    line-height: 1.2em;
    margin: 1.4em 0;
  }

  h2 {
    font-size: 1.1em;
    margin: 1em 0;
  }

  h3 {
    font-size: 1em;
    margin: 1.2em 0;
  }

  h4 {
    margin: 1.1em 0;
  }

  .img {
    display: inline-block;
  }

  .emoji {
    display: inline-block;
    width: var(--emoji-size, 32px);
    height: var(--emoji-size, 32px);
  }

  .img,
  video {
    max-width: 100%;
    max-height: 400px;
    vertical-align: middle;
    object-fit: contain;
  }

  .greentext {
    color: var(--funtextGreentext);
  }

  .cyantext {
    color: var(--funtextCyantext);
  }
}

a .RichContent {
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--link) !important;
}
