
.user-note {
  display: flex;
  flex-direction: column;

  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75em;

    .btn {
      min-width: 95px;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: right;

      .btn {
        margin-left: 0.5em;
      }
    }
  }

  .note-text {
    align-self: stretch;
  }

  .note-text.-blank {
    font-style: italic;
    color: var(--textFaint);
  }
}
