
.list-card {
  display: flex;
}

.list-name {
  flex-grow: 1;
}

.list-name,
.button-list-edit {
  margin: 0;
  padding: 1em;
  color: var(--link);
}
