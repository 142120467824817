.MentionLink {
  position: relative;
  white-space: normal;
  display: inline;
  word-break: normal;

  & .new,
  & .original {
    display: inline;
    border-radius: 2px;
  }

  .mention-avatar {
    border-radius: var(--roundness);
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    user-select: none;
    margin-right: 0.2em;
  }

  .full {
    position: absolute;
    display: inline-block;
    pointer-events: none;
    opacity: 0;
    top: 100%;
    left: 0;
    height: 100%;
    word-wrap: normal;
    white-space: nowrap;
    transition: opacity 0.2s ease;
    z-index: 1;
    margin-top: 0.25em;
    padding: 0.5em;
    user-select: all;
  }

  & .short.-with-tooltip,
  & .you {
    user-select: none;
  }

  & .short,
  & .full {
    white-space: nowrap;
  }

  .shortName {
    white-space: normal;
  }

  .new {
    &.-you {
      .shortName {
        font-weight: 600;
      }
    }

    &.-has-selection {
      --color: var(--selectionText);
      --link: var(--selectionText);

      background-color: var(--selectionBackground);
    }

    .at {
      color: var(--link);
      opacity: 0.8;
      display: inline-block;
      line-height: 1;
      padding: 0 0.1em;
      vertical-align: -25%;
      margin: 0;
    }

    &.-striped {
      & .shortName {
        background-image:
          repeating-linear-gradient(
            135deg,
            var(--____highlight-tintColor),
            var(--____highlight-tintColor) 5px,
            var(--____highlight-tintColor2) 5px,
            var(--____highlight-tintColor2) 10px
          );
      }
    }

    &.-solid {
      .shortName {
        background-image: linear-gradient(var(--____highlight-tintColor2), var(--____highlight-tintColor2));
      }
    }

    &.-side {
      .shortName {
        box-shadow: 0 -5px 3px -4px inset var(--____highlight-solidColor);
      }
    }
  }

  .serverName.-faded {
    color: var(--linkFaint);
  }
}

.mention-link-popover {
  max-width: 70ch;
  max-height: 20rem;
  overflow: hidden;
}
