.Report {
  .report-content {
    margin: 0.5em 0 1em;
  }

  .report-state {
    margin: 0.5em 0 1em;
  }

  .reported-status {
    border: 1px solid var(--border);
    border-radius: var(--roundness);
    display: block;
    padding: 0.5em;
    margin: 0.5em 0;

    .status-content {
      pointer-events: none;
    }

    .reported-status-heading {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 0.2em;
    }

    .reported-status-name {
      font-weight: bold;
    }
  }

  .note {
    width: 100%;
    margin-bottom: 0.5em;
  }
}
