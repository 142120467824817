
@import "../../mixins";

.ReactButton {
  .reaction-picker-filter {
    padding: 0.5em;
    display: flex;

    input {
      flex: 1;
    }
  }

  .reaction-picker-divider {
    height: 1px;
    width: 100%;
    margin: 0.5em;
    background-color: var(--border);
  }

  .reaction-picker {
    width: 10em;
    height: 9em;
    font-size: 1.5em;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    padding: 0.5em;
    text-align: center;
    align-content: flex-start;
    user-select: none;
    mask:
      linear-gradient(to top, white 0, transparent 100%) bottom no-repeat,
      linear-gradient(to bottom, white 0, transparent 100%) top no-repeat,
      linear-gradient(to top, white, white);
    transition: mask-size 150ms;
    mask-size: 100% 20px, 100% 20px, auto;

    /* Autoprefixed seem to ignore this one, and also syntax is different */
    mask-composite: xor;
    mask-composite: exclude;

    .emoji-button {
      cursor: pointer;
      flex-basis: 20%;
      line-height: 1.5;
      align-content: center;

      &:hover {
        transform: scale(1.25);
      }
    }
  }

  .popover-trigger {
    padding: 10px;
    margin: -10px;

    @include unfocused-style {
      .focus-marker {
        visibility: hidden;
      }
    }

    @include focused-style {
      .focus-marker {
        visibility: visible;
      }
    }
  }
}

