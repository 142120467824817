
/* TODO fix order of styles */
label.Select {
  padding: 0;

  select {
    appearance: none;
    background: transparent;
    border: none;
    color: var(--text);
    margin: 0;
    padding: 0 2em 0 0.2em;
    font-family: var(--font);
    font-size: 1em;
    width: 100%;
    z-index: 1;
    height: 2em;
    line-height: 16px;
  }

  .select-down-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 5px;
    height: 100%;
    width: 0.875em;
    font-family: var(--font);
    line-height: 2;
    z-index: 0;
    pointer-events: none;
  }
}
