
.chat-title {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  --emoji-size: 14px;

  .username {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline;
    word-wrap: break-word;
    overflow: hidden;
  }

  .avatar-container {
    align-self: center;
    line-height: 1;
  }

  .titlebar-avatar {
    margin-right: 0.5em;
    height: 1.5em;
    width: 1.5em;
    border-radius: var(--roundness);

    &.animated::before {
      display: none;
    }
  }
}
