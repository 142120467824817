
.Flash {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;

  .player {
    height: 100%;
    width: 100%;
  }

  .placeholder {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg);
    color: var(--link);
  }

  .hider {
    top: 0;
  }

  .label {
    text-align: center;
    flex: 1 1 0;
    line-height: 1.2;
    white-space: normal;
    word-wrap: normal;
  }

  .hidden {
    display: none;
    visibility: "hidden";
  }
}
