.chat-new {
  .input-wrap {
    display: flex;
    margin: 0.7em 0.5em;

    input {
      width: 100%;
    }
  }

  .search-icon {
    margin-right: 0.3em;
  }

  .member-list {
    padding-bottom: 0.7rem;
  }

  .go-back-button {
    text-align: center;
    line-height: 1;
    height: 100%;
    align-self: start;
    width: var(--__panel-heading-height-inner);
  }
}
