.Status {
  min-width: 0;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;

  &:hover {
    --_still-image-img-visibility: visible;
    --_still-image-canvas-visibility: hidden;
    --_still-image-label-visibility: hidden;
  }

  .gravestone {
    padding: var(--status-margin);
    display: flex;

    .deleted-text {
      margin: 0.5em 0;
      align-items: center;
    }
  }

  .status-container {
    display: flex;
    padding: var(--status-margin);

    > * {
      min-width: 0;
    }

    &.-repeat {
      padding-top: 0;
    }
  }

  .pin {
    padding: var(--status-margin) var(--status-margin) 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  ._misclick-prevention & {
    pointer-events: none;

    .attachments {
      pointer-events: initial;
      cursor: initial;
    }
  }

  .left-side {
    margin-right: var(--status-margin);
  }

  .right-side {
    flex: 1;
    min-width: 0;
  }

  .usercard {
    margin-bottom: var(--status-margin);
  }

  .status-username {
    white-space: nowrap;
    overflow: hidden;
    max-width: 85%;
    font-weight: bold;
    flex-shrink: 1;
    margin-right: 0.4em;
    text-overflow: ellipsis;

    --_still_image-label-scale: 0.25;
    --emoji-size: 14px;
  }

  .status-favicon {
    height: 18px;
    width: 18px;
    margin-right: 0.4em;
  }

  .status-heading {
    margin-bottom: 0.5em;
  }

  .heading-name-row {
    display: flex;
    justify-content: space-between;
    line-height: 1.3;

    a {
      display: inline-block;
      word-break: break-all;
    }
  }

  .account-name {
    min-width: 1.6em;
    margin-right: 0.4em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1 0;
  }

  .heading-left {
    display: flex;
    min-width: 0;
  }

  .heading-right {
    display: flex;
    flex-shrink: 0;

    .button-unstyled {
      padding: 5px;
      margin: -5px;
    }

    .svg-inline--fa {
      margin-left: 0.25em;
    }
  }

  .glued-label {
    display: inline-flex;
    white-space: nowrap;
  }

  .timeago {
    margin-right: 0.2em;
  }

  & .heading-reply-row,
  & .heading-edited-row {
    position: relative;
    align-content: baseline;
    font-size: 0.85em;
    margin-top: 0.2em;
    line-height: 130%;
    max-width: 100%;
    align-items: stretch;
  }

  & .reply-to-popover,
  & .reply-to-no-popover,
  & .mentions {
    min-width: 0;
    margin-right: 0.4em;
    flex-shrink: 0;
  }

  .reply-glued-label {
    margin-right: 0.5em;
  }

  .reply-to-popover {
    .reply-to:hover::before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid var(--faint);
      pointer-events: none;
    }

    .faint-link:hover {
      // override default
      text-decoration: none;
    }

    &.-strikethrough {
      .reply-to::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        width: 100%;
        border-bottom: 1px solid var(--faint);
        pointer-events: none;
      }
    }
  }

  & .mentions,
  & .reply-to {
    white-space: nowrap;
    position: relative;
  }

  & .mentions-text,
  & .reply-to-text {
    color: var(--faint);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .mentions-line {
    display: inline;
  }

  .replies {
    margin-top: 0.25em;
    line-height: 1.3;
    font-size: 0.85em;
    display: flex;
    flex-wrap: wrap;

    & > * {
      margin-right: 0.4em;
    }
  }

  .reply-link {
    height: 17px;
  }

  .repeat-info {
    padding: 0.4em var(--status-margin);

    .repeat-icon {
      color: var(--cGreen);
    }
  }

  .repeater-avatar {
    border-radius: var(--roundness);
    margin-left: 28px;
    width: 20px;
    height: 20px;
  }

  .repeater-name {
    text-overflow: ellipsis;
    margin-right: 0;

    .emoji {
      width: 14px;
      height: 14px;
      vertical-align: middle;
      object-fit: contain;
    }
  }

  .status-fadein {
    animation-duration: 0.4s;
    animation-name: fadein;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .status-actions {
    position: relative;
    width: 100%;
    display: flex;
    margin-top: var(--status-margin);

    > * {
      max-width: 4em;
      flex: 1;
    }
  }

  .muted {
    padding: 0.25em 0.6em;
    height: 1.2em;
    line-height: 1.2em;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;

    & .status-username,
    & .mute-thread,
    & .mute-words {
      word-wrap: normal;
      word-break: normal;
      white-space: nowrap;
    }

    & .status-username,
    & .mute-words {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .status-username {
      font-weight: normal;
      flex: 0 1 auto;
      margin-right: 0.2em;
      font-size: smaller;
    }

    .mute-thread {
      flex: 0 0 auto;
    }

    .mute-words {
      flex: 1 0 5em;
      margin-left: 0.2em;

      &::before {
        content: " ";
      }
    }

    .unmute {
      flex: 0 0 auto;
      margin-left: auto;
      display: block;
    }
  }

  .reply-form {
    padding-top: 0;
    padding-bottom: 0;
  }

  .reply-body {
    flex: 1;
  }

  .favs-repeated-users {
    margin-top: var(--status-margin);
  }

  .stats {
    width: 100%;
    display: flex;
    line-height: 1em;
  }

  .avatar-row {
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      left: 0;
      background-color: var(--textFaint);
    }
  }

  .stat-count {
    margin-right: var(--status-margin);
    user-select: none;

    .stat-title {
      color: var(--textFaint);
      font-size: 0.85em;
      text-transform: uppercase;
      position: relative;
    }

    .stat-number {
      font-weight: bolder;
      font-size: 1.1em;
      line-height: 1em;
      color: var(--text);
    }

    &:hover .stat-title {
      text-decoration: underline;
    }
  }

  @media all and (max-width: 800px) {
    .repeater-avatar {
      margin-left: 20px;
    }

    .post-avatar {
      width: 40px;
      height: 40px;

      // TODO define those other way somehow?
      // stylelint-disable rscss/class-format
      &.-compact {
        width: 32px;
        height: 32px;
      }
    }
  }

  .quoted-status {
    margin-top: 0.5em;
    border: 1px solid var(--border);
    border-radius: var(--roundness);

    &.-unavailable-prompt {
      padding: 0.5em;
    }
  }

  .display-quoted-status-button {
    margin: 0.5em;

    &-icon {
      color: inherit;
    }
  }
}
