
.ListsUserSearch {
  .input-wrap {
    display: flex;
    margin: 0.7em 0.5em;

    input {
      width: 100%;
    }
  }

  .search-icon {
    margin-right: 0.3em;
  }
}

