
.Gallery {
  .gallery-rows {
    display: flex;
    flex-direction: column;
  }

  .gallery-row {
    position: relative;
    height: 0;
    width: 100%;
    flex-grow: 1;

    .gallery-row-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-flow: row wrap;
      align-content: stretch;

      .gallery-item {
        margin: 0 0.5em 0 0;
        flex-grow: 1;
        height: 100%;
        box-sizing: border-box;
        // to make failed images a bit more noticeable on chromium
        min-width: 2em;

        &:last-child {
          margin: 0;
        }
      }

      &.-grid {
        width: 100%;
        height: auto;
        position: relative;
        display: grid;
        grid-gap: 0.5em;
        grid-template-columns: repeat(auto-fill, minmax(15em, 1fr));

        .gallery-item {
          margin: 0;
          height: 200px;
        }
      }
    }

    &.-grid,
    &.-minimal {
      height: auto;

      .gallery-row-inner {
        position: relative;
      }
    }

    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }

  &.-long {
    .gallery-rows {
      max-height: 25em;
      overflow: hidden;
      mask:
        linear-gradient(to top, white, transparent) bottom/100% 70px no-repeat,
        linear-gradient(to top, white, white);

      /* Autoprefixed seem to ignore this one, and also syntax is different */
      mask-composite: xor;
      mask-composite: exclude;
    }
  }

  .many-attachments-text {
    text-align: center;
    line-height: 2;
  }

  .many-attachments-buttons {
    display: flex;
  }

  .many-attachments-button {
    display: flex;
    flex: 1;
    justify-content: center;
    line-height: 2;

    button {
      padding: 0 2em;
    }
  }
}
