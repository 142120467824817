
/* popover styles load on-demand, so we need to override */
.status-popover.popover {
  font-size: 1rem;
  min-width: 15em;
  max-width: 95%;
  border-color: var(--border);
  border-style: solid;
  border-width: 1px;

  /* TODO cleanup this */
  .Status.Status {
    border: none;
  }

  .status-preview-no-content {
    padding: 1em;
    text-align: center;

    i {
      font-size: 2em;
    }
  }
}

