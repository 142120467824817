.Timeline {
  .timeline-body {
    background: none;
    backdrop-filter: none;
  }

  .alert-badge {
    font-size: 0.75em;
    line-height: 1;
    text-align: right;
    border-radius: var(--roundness);
    position: absolute;
    left: calc(50% - 0.5em);
    top: calc(50% - 0.4em);
    padding: 0.2em;
    margin-left: 0.7em;
    margin-top: -1em;
  }

  .loadmore-button {
    position: relative;
  }

  &.-blocked {
    cursor: progress;
  }

  .conversation-heading {
    top: calc(var(--__panel-heading-height) * var(--currentPanelStack, 2));
    z-index: 2;
  }

  &.-embedded {
    .timeline-heading {
      text-align: center;
      line-height: 2.75em;
      padding: 0 0.5em;

      // Override the shrug empty filler
      &:empty::before {
        content: initial;
      }

      .button-default,
      .alert {
        line-height: 2em;
        width: 100%;
      }
    }
  }
}
