
.follow-request-card-content-container {
  display: flex;
  flex-flow: row wrap;

  button {
    margin-top: 0.5em;
    margin-right: 0.5em;
    flex: 1 1;
    max-width: 12em;
    min-width: 8em;

    &:last-child {
      margin-right: 0;
    }
  }
}
