
.follow-card {
  &-content-container {
    flex-shrink: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    line-height: 1.5em;
  }

  &-button {
    margin-top: 0.5em;
    padding: 0 1.5em;
    margin-left: 1em;
  }

  &-follow-button {
    margin-top: 0.5em;
    margin-left: auto;
    width: 10em;
  }
}
