.chat-view {
  display: flex;
  height: 100%;

  .chat-view-inner {
    height: auto;
    width: 100%;
    overflow: visible;
    display: flex;
  }

  .chat-view-body {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: visible;
    min-height: calc(100vh - var(--navbar-height));
    margin: 0;
    border-radius: var(--roundness);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &::after {
      border-radius: 0;
    }
  }

  .message-list {
    padding: 0 0.8em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .footer {
    position: sticky;
    bottom: 0;
    z-index: 1;
  }

  .chat-view-heading {
    grid-template-columns: auto minmax(50%, 1fr);
  }

  .go-back-button {
    text-align: center;
    line-height: 1;
    height: 100%;
    align-self: start;
    width: var(--__panel-heading-height-inner);
  }

  .jump-to-bottom-button {
    width: 2.5em;
    height: 2.5em;
    border-radius: 100%;
    position: absolute;
    right: 1.3em;
    top: -3.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 1px rgb(0 0 0 / 30%), 0 2px 4px rgb(0 0 0 / 30%);
    z-index: 10;
    transition: 0.35s all;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    opacity: 0;
    visibility: hidden;
    cursor: pointer;

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    .unread-message-count {
      font-size: 0.8em;
      left: 50%;
      margin-top: -1rem;
      padding: 0.1em;
      border-radius: 50px;
      position: absolute;
    }

    .chat-loading-error {
      width: 100%;
      display: flex;
      align-items: flex-end;
      height: 100%;

      .error {
        width: 100%;
      }
    }
  }
}
