.with-load-more {
  &-footer {
    padding: 10px;
    text-align: center;
    border-top: 1px solid;
    border-top-color: var(--border);

    .error {
      font-size: 1rem;
    }

    a {
      cursor: pointer;
    }
  }
}
