
.input.emoji-input {
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  .emoji-picker-icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.2em 0.25em;
    font-size: 1.3em;
    cursor: pointer;
    line-height: 24px;

    &:hover i {
      color: var(--text);
    }
  }

  .emoji-picker-panel {
    position: absolute;
    z-index: 20;
    margin-top: 2px;

    &.hide {
      display: none;
    }
  }

  input,
  textarea {
    flex: 1 0 auto;
    color: inherit;
    /* stylelint-disable-next-line declaration-no-important */
    background: none !important;
    box-shadow: none;
    border: none;
    outline: none;
  }

  &.with-picker input {
    padding-right: 30px;
  }

  .hidden-overlay {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;

    /* DEBUG STUFF */
    color: red;

    /* set opacity to non-zero to see the overlay */

    .caret {
      width: 0;
      margin-right: calc(-1ch - 1px);
      border: 1px solid red;
    }
  }
}

.autocomplete {
  &-panel {
    position: absolute;
  }

  &-item.menu-item {
    display: flex;
    padding-top: 0;
    padding-bottom: 0;

    .image {
      width: calc(var(--__line-height) + var(--__vertical-gap) * 2);
      height: calc(var(--__line-height) + var(--__vertical-gap) * 2);
      line-height: var(--__line-height);
      text-align: center;
      margin-right: var(--__horizontal-gap);

      img {
        width: calc(var(--__line-height) + var(--__vertical-gap) * 2);
        height: calc(var(--__line-height) + var(--__vertical-gap) * 2);
        object-fit: contain;
      }

      span {
        font-size: var(--__line-height);
        line-height: var(--__line-height);
      }
    }

    .label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 0.1em 0 0.2em;

      .displayText {
        line-height: 1.5;
      }

      .detailText {
        font-size: 9px;
        line-height: 9px;
      }
    }
  }
}
