
.post-status-form {
  position: relative;

  .attachments {
    margin-bottom: 0.5em;
  }

  .form-bottom {
    display: flex;
    justify-content: space-between;
    padding: 0.5em;
    height: 2.5em;

    button {
      width: 10em;
    }

    p {
      margin: 0.35em;
      padding: 0.35em;
      display: flex;
    }
  }

  .form-bottom-left {
    display: flex;
    flex: 1;
    padding-right: 7px;
    margin-right: 7px;
    max-width: 10em;
  }

  .preview-heading {
    display: flex;
    padding-left: 0.5em;
  }

  .preview-toggle {
    flex: 1;
    cursor: pointer;
    user-select: none;

    &:hover {
      text-decoration: underline;
    }

    svg,
    i {
      margin-left: 0.2em;
      font-size: 0.8em;
      transform: rotate(90deg);
    }
  }

  .preview-container {
    margin-bottom: 1em;
  }

  .preview-error {
    font-style: italic;
    color: var(--textFaint);
  }

  .preview-status {
    border: 1px solid var(--border);
    border-radius: var(--roundness);
    padding: 0.5em;
    margin: 0;
  }

  .reply-or-quote-selector {
    margin-bottom: 0.5em;
  }

  .text-format {
    .only-format {
      color: var(--textFaint);
    }
  }

  .visibility-tray {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    align-items: baseline;
  }

  .visibility-notice.edit-warning {
    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  // Order is not necessary but a good indicator
  .media-upload-icon {
    order: 1;
    justify-content: left;
  }

  .emoji-icon {
    order: 2;
    justify-content: center;
  }

  .poll-icon {
    order: 3;
    justify-content: right;
  }

  .media-upload-icon,
  .poll-icon,
  .emoji-icon {
    font-size: 1.85em;
    line-height: 1.1;
    flex: 1;
    padding: 0 0.1em;
    display: flex;
    align-items: center;
  }

  .error {
    text-align: center;
  }

  .media-upload-wrapper {
    margin-right: 0.2em;
    margin-bottom: 0.5em;
    width: 18em;

    img,
    video {
      object-fit: contain;
      max-height: 10em;
    }

    .video {
      max-height: 10em;
    }

    input {
      flex: 1;
      width: 100%;
    }
  }

  .status-input-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;
  }

  .btn[disabled] {
    cursor: not-allowed;
  }

  form {
    display: flex;
    flex-direction: column;
    margin: 0.6em;
    position: relative;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    padding: 0.25em 0.5em 0.5em;
    line-height: 1.85;
  }

  .input.form-post-body {
    // TODO: make a resizable textarea component?
    box-sizing: content-box; // needed for easier computation of dynamic size
    overflow: hidden;
    transition: min-height 200ms 100ms;
    // stock padding + 1 line of text (for counter)
    padding-bottom: calc(var(--_padding) + var(--post-line-height) * 1em);
    // two lines of text
    height: calc(var(--post-line-height) * 1em);
    min-height: calc(var(--post-line-height) * 1em);
    resize: none;
    background: transparent;

    &.scrollable-form {
      overflow-y: auto;
    }
  }

  .main-input {
    position: relative;
  }

  .character-counter {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0 0.5em;

    &.error {
      color: var(--cRed);
    }
  }

  @keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 0.6; }
  }

  @keyframes fade-out {
    from { opacity: 0.6; }
    to { opacity: 0; }
  }

  .drop-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
    color: var(--text);
    background-color: var(--bg);
    border-radius: var(--roundness);
    border: 2px dashed var(--text);
  }
}
