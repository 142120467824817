
.user-list-popover {
  padding: 0.5em;

  --emoji-size: 16px;

  .user-list-row {
    padding: 0.25em;
    display: flex;
    flex-direction: row;

    .user-list-names {
      display: flex;
      flex-direction: column;
      margin-left: 0.5em;
      min-width: 5em;

      img {
        width: 1em;
        height: 1em;
      }
    }

    .user-list-screen-name {
      font-size: 0.65em;
    }
  }
}

