
.chat-list {
  min-height: 25em;
  margin-bottom: 0;
}

.emtpy-chat-list-alert {
  padding: 3em;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  color: var(--textFaint);
}

