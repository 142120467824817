/* stylelint-disable no-descending-specificity */
.tab-switcher {
  display: flex;

  .tab-icon {
    margin: 0.2em auto;
    display: block;
  }

  &.top-tabs {
    flex-direction: column;

    > .tabs {
      width: 100%;
      overflow-y: hidden;
      overflow-x: auto;
      padding-top: 5px;
      flex-direction: row;
      flex: 0 0 auto;

      &::after,
      &::before {
        content: "";
        flex: 1 1 auto;
        border-bottom: 1px solid;
        border-bottom-color: var(--border);
      }

      .tab-wrapper {
        height: 2em;

        &:not(.active)::after {
          left: 0;
          right: 0;
          bottom: 0;
          border-bottom: 1px solid;
          border-bottom-color: var(--border);
        }
      }

      .tab {
        width: 100%;
        min-width: 1px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding-bottom: 99px;
        margin-bottom: 6px - 99px;
      }
    }

    .contents.scrollable-tabs {
      flex-basis: 0;
    }
  }

  &.side-tabs {
    flex-direction: row;

    @media all and (max-width: 800px) {
      overflow-x: auto;
    }

    > .contents {
      flex: 1 1 auto;
    }

    > .tabs {
      flex: 0 0 auto;
      overflow-y: auto;
      overflow-x: hidden;
      flex-direction: column;

      &::after,
      &::before {
        flex-shrink: 0;
        flex-basis: 0.5em;
        content: "";
        border-right: 1px solid;
        border-right-color: var(--border);
      }

      &::after {
        flex-grow: 1;
      }

      &::before {
        flex-grow: 0;
      }

      .tab-wrapper {
        min-width: 10em;
        display: flex;
        flex-direction: column;

        @media all and (max-width: 800px) {
          min-width: 4em;
        }

        &:not(.active)::after {
          top: 0;
          right: 0;
          bottom: 0;
          border-right: 1px solid;
          border-right-color: var(--border);
        }

        &::before {
          flex: 0 0 6px;
          content: "";
          border-right: 1px solid;
          border-right-color: var(--border);
        }

        &:last-child .tab {
          margin-bottom: 0;
        }
      }

      .tab {
        flex: 1;
        box-sizing: content-box;
        min-width: 10em;
        min-width: 1px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-left: 1em;
        padding-right: calc(1em + 200px);
        margin-right: -200px;
        margin-left: 1em;

        @media all and (max-width: 800px) {
          padding-left: 0.25em;
          padding-right: calc(0.25em + 200px);
          margin-right: calc(0.25em - 200px);
          margin-left: 0.25em;

          .text {
            display: none;
          }
        }
      }
    }
  }

  .contents {
    flex: 1 0 auto;
    min-height: 0;

    .hidden {
      display: none;
    }

    .full-height:not(.hidden) {
      height: 100%;
      display: flex;
      flex-direction: column;

      > *:not(.mobile-label) {
        flex: 1;
      }
    }

    &.scrollable-tabs {
      overflow-y: auto;
    }
  }

  .tab {
    user-select: none;
    color: var(--text);
    border: none;
    cursor: pointer;
    box-shadow: var(--shadow);
    font-size: 1em;
    font-family: var(--font);
    border-radius: var(--roundness);
    background-color: var(--background);
    position: relative;
    white-space: nowrap;
    padding: 6px 1em;

    &:not(.active) {
      z-index: 4;

      &:hover {
        z-index: 6;
      }
    }

    &.active {
      background: transparent;
      z-index: 5;
    }

    img {
      max-height: 26px;
      vertical-align: top;
      margin-top: -5px;
    }
  }

  .tabs {
    display: flex;
    position: relative;
    box-sizing: border-box;

    &::after,
    &::before {
      display: block;
      flex: 1 1 auto;
    }
  }

  .tab-wrapper {
    position: relative;
    display: flex;
    flex: 0 0 auto;

    &:not(.active) {
      &::after {
        content: "";
        position: absolute;
        z-index: 7;
      }
    }
  }

  .mobile-label {
    padding-left: 0.3em;
    padding-bottom: 0.25em;
    margin-top: 0.5em;
    margin-left: 0.2em;
    margin-bottom: 0.25em;
    border-bottom: 1px solid var(--border);

    @media all and (min-width: 800px) {
      display: none;
    }
  }
}
/* stylelint-enable no-descending-specificity */
