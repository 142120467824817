
  .who-to-follow * {
    vertical-align: middle;
  }

  .who-to-follow img {
    width: 32px;
    height: 32px;
  }

  .who-to-follow {
    padding: 0 1em;
    margin: 0;
  }

  .who-to-follow-items {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    margin: 1em 0;
  }

  .who-to-follow-more {
    padding: 0;
    margin: 1em 0;
    text-align: center;
  }
