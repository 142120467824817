
.user-panel {
  .panel {
    background: var(--background);
    backdrop-filter: var(--backdrop-filter);
  }

  .signed-in {
    overflow: visible;
    z-index: 10;
  }
}
