
.registration-form {
  display: flex;
  flex-direction: column;
  margin: 0.6em;

  .container {
    display: flex;
    flex-direction: row;

    > * {
      min-width: 0;
    }
  }

  .terms-of-service {
    flex: 0 1 50%;
    margin: 0.8em;
  }

  .text-fields {
    margin-top: 0.6em;
    flex: 1 0;
    display: flex;
    flex-direction: column;
  }

  textarea {
    min-height: 100px;
    resize: vertical;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    padding: 0.3em 0;
    line-height: 2;
    margin-bottom: 1em;
  }

  .form-group--error {
    animation-name: shakeError;
    animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
  }

  .form-group--error .form--label {
    color: var(--cRed);
  }

  .form-error {
    margin-top: -0.7em;
    text-align: left;

    span {
      font-size: 0.85em;
    }
  }

  .form-error ul {
    list-style: none;
    padding: 0 0 0 5px;
    margin-top: 0;

    li::before {
      content: "• ";
    }
  }

  form textarea {
    line-height: 16px;
    resize: vertical;
  }

  .captcha {
    max-width: 350px;
    margin-bottom: 0.4em;
  }

  .btn {
    margin-top: 0.6em;
    height: 2em;
  }

  .error {
    text-align: center;
  }
}

.registration-notice {
  margin: 0.6em;
}

@media all and (max-width: 800px) {
  .registration-form .container {
    flex-direction: column-reverse;
  }
}
