.emoji-picker {
  --__emoji-picker-header: 2.2em;

  width: 25em;
  max-width: calc(100vw - 20px); // popover gives 10px margin from window edge
  display: flex;
  flex-direction: column;

  &-header-image {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: var(--__emoji-picker-header);
    max-width: var(--__emoji-picker-header);
    height: var(--__emoji-picker-header);
    max-height: var(--__emoji-picker-header);

    .still-image {
      width: var(--__emoji-picker-header);
      max-width: var(--__emoji-picker-header);
      height: var(--__emoji-picker-header);
      max-height: var(--__emoji-picker-header);
      object-fit: contain;

      --_still_image-label-scale: 0.5;
    }
  }

  .keep-open,
  .too-many-emoji,
  .hide-custom-emoji {
    padding: 0.5em;
    line-height: normal;
  }

  .hide-custom-emoji {
    padding-top: 0;
  }

  .too-many-emoji {
    display: flex;
    flex-direction: column;
  }

  .keep-open-label {
    padding: 0 0.5em;
    display: flex;
  }

  .heading {
    display: flex;
    padding: 0.7em 0.5em 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 0;
  }

  .emoji-tabs {
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .additional-tabs {
    display: flex;
    border-left: 1px solid;
    border-left-color: var(--border);
    padding-left: 0.5em;
    flex: 0 0 auto;
  }

  .additional-tabs,
  .emoji-tabs {
    flex-basis: auto;
    display: flex;
    align-content: center;
    scrollbar-width: thin;

    &-item {
      padding: 0 0.5em;
      cursor: pointer;
      width: var(--__emoji-picker-header);
      max-width: var(--__emoji-picker-header);
      height: var(--__emoji-picker-header);
      max-height: var(--__emoji-picker-header);
      display: flex;
      align-items: center;

      .svg-inline--fa {
        font-size: 1.85em;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &.toggled {
        border-bottom: 0.2em solid;
      }
    }
  }

  .sticker-picker {
    flex: 1 1 auto;
  }

  .stickers,
  .emoji {
    &-content {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      min-height: 0;

      &.hidden {
        opacity: 0;
        pointer-events: none;
        position: absolute;
      }
    }
  }

  .emoji {
    &-search {
      padding: 0.3em;
      flex: 0 0 auto;

      input {
        width: 100%;
      }
    }

    &-groups {
      height: 100%;
      min-height: 200px;
      flex: 1 1 1px;
      position: relative;
      overflow: auto;
      scrollbar-gutter: stable both-edges;
      user-select: none;
      mask:
        linear-gradient(to top, white 0, transparent 100%) bottom no-repeat,
        linear-gradient(to bottom, white 0, transparent 100%) top no-repeat,
        linear-gradient(to top, white, white);
      transition: mask-size 150ms;
      mask-size: 100% 20px, 100% 20px, auto;
      // Autoprefixed seem to ignore this one, and also syntax is different
      mask-composite: xor;
      mask-composite: exclude;

      &.scrolled {
        &-top {
          mask-size: 100% 20px, 100% 0, auto;
        }

        &-bottom {
          mask-size: 100% 0, 100% 20px, auto;
        }
      }
    }

    &-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: left;

      &-title {
        font-size: 0.85em;
        width: 100%;
        margin: 0;
        padding-left: 0.3em;

        &.disabled {
          display: none;
        }
      }
    }

    &-item {
      width: var(--emoji-size);
      height: var(--emoji-size);
      box-sizing: border-box;
      display: flex;
      line-height: var(--emoji-size);
      align-items: center;
      justify-content: center;
      margin: 0.2em;
      cursor: pointer;

      .emoji-picker-emoji.-custom {
        object-fit: contain;
        width: var(--emoji-size);
        max-width: var(--emoji-size);
        height: var(--emoji-size);
        max-height: var(--emoji-size);

        --_still_image-label-scale: 0.5;
      }

      .emoji-picker-emoji.-unicode {
        font-size: 1.6em;
        overflow: hidden;
      }
    }
  }
}
