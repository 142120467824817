
.SearchBar {
  display: inline-flex;
  align-items: baseline;
  vertical-align: baseline;
  justify-content: flex-end;

  &.-expanded {
    width: 100%;
  }

  .search-bar-input,
  .search-button {
    height: 29px;
  }

  .search-bar-input {
    flex: 1 0 auto;
  }

  .cancel-search {
    height: 50px;
  }

  .cancel-icon {
    color: var(--text);
  }
}

