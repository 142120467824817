
// TODO: unify with other modals.
.dark-overlay {
  &::before {
    bottom: 0;
    content: " ";
    display: block;
    cursor: default;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    background: rgb(27 31 35 / 50%);
    z-index: 2000;
  }
}

.dialog-modal.panel {
  top: 0;
  left: 50%;
  max-height: 80vh;
  max-width: 90vw;
  margin: 15vh auto;
  position: fixed;
  transform: translateX(-50%);
  z-index: 2001;
  cursor: default;
  display: block;

  .dialog-modal-heading {
    .title {
      text-align: center;
    }
  }

  .dialog-modal-content {
    margin: 0;
    padding: 1rem;
    white-space: normal;
  }

  .dialog-modal-footer {
    margin: 0;
    padding: 0.5em;
    border-top: 1px solid var(--border);
    display: flex;
    justify-content: flex-end;

    button {
      width: auto;
      margin-left: 0.5rem;
    }
  }
}

