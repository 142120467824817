
.moderation-tools-popover {
  height: 100%;

  .trigger {
    /* stylelint-disable-next-line declaration-no-important */
    display: flex !important;
    height: 100%;
  }
}

.moderation-tools-button {
  svg,
  i {
    font-size: 0.8em;
  }
}
