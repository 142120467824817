
.NavigationPins {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: 100%;

  .pinned-item {
    position: relative;
    flex: 1 0 3em;
    min-width: 2em;
    text-align: center;
    overflow: visible;
    box-sizing: border-box;
    height: 100%;

    & .svg-inline--fa,
    & .iconLetter {
      margin: 0;
    }

    &.toggled {
      margin-bottom: -4px;
      border-bottom: 4px solid;
    }
  }
}
