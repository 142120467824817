
.ExtraNotifications {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .notification {
    width: 100%;
    border-bottom: 1px solid;
    border-color: var(--border);
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .extra-notification {
    padding: 1em;
  }

  .icon {
    margin-right: 0.5em;
  }

  .tip {
    display: inline;
  }
}
