
.panel-loading {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  color: var(--text);

  .loading-text svg {
    line-height: 0;
    vertical-align: middle;
    color: var(--text);
  }
}
