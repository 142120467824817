
.announcement-editor {
  display: flex;
  align-items: stretch;
  flex-direction: column;

  .announcement-metadata {
    margin-top: 0.5em;
  }

  .post-textarea {
    resize: vertical;
    height: 10em;
    overflow: none;
    box-sizing: content-box;
  }
}
