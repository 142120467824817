
.password-reset-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.6em;

  .container {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin-top: 0.6em;
    max-width: 18rem;

    > * {
      min-width: 0;
    }
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    padding: 0.3em 0;
    line-height: 1.85em;
  }

  .error {
    text-align: center;
    animation-name: shakeError;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
  }

  .alert {
    padding: 0.5em;
    margin: 0.3em 0 1em;
  }

  .notice-dismissible {
    padding-right: 2rem;
  }

  .dismiss {
    cursor: pointer;
  }
}

