
$modal-view-button-icon-height: 3em;
$modal-view-button-icon-half-height: calc(#{$modal-view-button-icon-height} / 2);
$modal-view-button-icon-width: 3em;
$modal-view-button-icon-margin: 0.5em;

.media-modal-view {
  @keyframes media-fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .modal-image-container {
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    justify-content: center;

    &-inner {
      width: 100%;
      height: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .description,
  .counter {
    /* Hardcoded since background is also hardcoded */
    color: white;
    margin-top: 1em;
    text-shadow: 0 0 10px black, 0 0 10px black;
    padding: 0.2em 2em;
  }

  .description {
    flex: 0 0 auto;
    overflow-y: auto;
    min-height: 1em;
    max-width: 500px;
    max-height: 9.5em;
    word-break: break-all;
  }

  .modal-image {
    max-width: 100%;
    max-height: 100%;
    image-orientation: from-image; // NOTE: only FF supports this
    animation: 0.1s cubic-bezier(0.7, 0, 1, 0.6) media-fadein;

    &.loading {
      opacity: 0.5;
    }
  }

  .loading-spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: white;
    }
  }

  .modal-view-button {
    border: 0;
    padding: 0;
    opacity: 0;
    box-shadow: none;
    background: none;
    appearance: none;
    overflow: visible;
    cursor: pointer;
    transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);
    height: $modal-view-button-icon-height;
    width: $modal-view-button-icon-width;

    .button-icon {
      position: absolute;
      height: $modal-view-button-icon-height;
      width: $modal-view-button-icon-width;
      font-size: 1rem;
      line-height: $modal-view-button-icon-height;
      color: #fff;
      text-align: center;
      background-color: rgb(0 0 0 / 30%);
    }
  }

  .modal-view-button-arrow {
    position: absolute;
    display: block;
    top: 50%;
    margin-top: $modal-view-button-icon-half-height;
    width: $modal-view-button-icon-width;
    height: $modal-view-button-icon-height;

    .arrow-icon {
      position: absolute;
      top: 0;
      line-height: $modal-view-button-icon-height;
      color: #fff;
      text-align: center;
      background-color: rgb(0 0 0 / 30%);
    }

    &--prev {
      left: 0;

      .arrow-icon {
        left: $modal-view-button-icon-margin;
      }
    }

    &--next {
      right: 0;

      .arrow-icon {
        right: $modal-view-button-icon-margin;
      }
    }
  }

  .modal-view-button-hide {
    position: absolute;
    top: 0;
    right: 0;

    .button-icon {
      top: $modal-view-button-icon-margin;
      right: $modal-view-button-icon-margin;
    }
  }
}

.modal-view.media-modal-view {
  z-index: var(--ZI_media_modal);
  flex-direction: column;

  .modal-view-button-arrow,
  .modal-view-button-hide {
    opacity: 0.75;

    &:focus,
    &:hover {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      opacity: 1;
    }
  }

  overflow: hidden;
}
