
.ListEdit {
  --panel-body-padding: 0.5em;

  height: calc(100vh - var(--navbar-height));
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .list-edit-heading {
    grid-template-columns: auto minmax(50%, 1fr);
  }

  .panel-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
  }

  .list-member-management {
    flex: 1 0 auto;
  }

  .search-icon {
    margin-right: 0.3em;
  }

  .users-list {
    padding-bottom: 0.7rem;
    overflow-y: auto;
  }

  & .search-list,
  & .members-list {
    overflow: hidden;
    flex-direction: column;
    min-height: 0;
  }

  .go-back-button {
    text-align: center;
    line-height: 1;
    height: 100%;
    align-self: start;
    width: var(--__panel-heading-height-inner);
  }

  .btn {
    margin: 0 0.5em;
  }

  .panel-footer {
    grid-template-columns: minmax(10%, 1fr);

    .footer-button {
      min-width: 9em;
    }
  }
}
