
@import "../../mixins";

.checkbox {
  position: relative;
  display: inline-block;
  min-height: 1.2em;

  & > &-indicator {
    /* Reset .input stuff */
    padding: 0;
    margin: 0;
    position: relative;
    line-height: inherit;
    display: inline;
    padding-left: 1.2em;
    box-shadow: none;
  }

  &-indicator::before {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    content: "✓";
    transition: color 200ms;
    width: 1.1em;
    height: 1.1em;
    border-radius: var(--roundness);
    box-shadow: var(--shadow);
    background-color: var(--background);
    vertical-align: top;
    text-align: center;
    line-height: 1.1em;
    font-size: 1.1em;
    color: transparent;
    overflow: hidden;
    box-sizing: border-box;
  }

  &.disabled {
    .checkbox-indicator::before,
    .label {
      opacity: 0.5;
    }

    .label {
      color: var(--text);
    }
  }

  input[type="checkbox"] {
    &:checked + .checkbox-indicator::before {
      color: var(--text);
    }

    &:indeterminate + .checkbox-indicator::before {
      content: "–";
      color: var(--text);
    }
  }

  &.indeterminate-fix {
    input[type="checkbox"] + .checkbox-indicator::before {
      content: "–";
    }
  }

  & > span {
    margin-left: 0.5em;
  }
}
