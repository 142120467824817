
.user-reporting-panel {
  width: 90vw;
  max-width: 700px;
  min-height: 20vh;
  max-height: 80vh;

  .panel-body {
    display: flex;
    flex-direction: column-reverse;
    border-top: 1px solid;
    border-color: var(--border);
    overflow: hidden;
  }

  &-left {
    padding: 1.1em 0.7em 0.7em;
    line-height: var(--post-line-height);
    box-sizing: border-box;

    > div {
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      margin-top: 0;
    }

    textarea.form-control {
      line-height: 16px;
      resize: none;
      overflow: hidden;
      transition: min-height 200ms 100ms;
      min-height: 44px;
      width: 100%;
    }

    .btn {
      min-width: 10em;
      padding: 0 2em;
    }

    .alert {
      margin: 1em 0 0;
      line-height: 1.3em;
    }
  }

  &-right {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  &-sitem {
    display: flex;
    justify-content: space-between;

    /* TODO cleanup this */
    > .Status {
      flex: 1;
    }

    > .checkbox {
      margin: 0.75em;
    }
  }

  @media all and (min-width: 801px) {
    .panel-body {
      flex-direction: row;
    }

    &-left {
      width: 50%;
      max-width: 320px;
      border-right: 1px solid;
      border-color: var(--border);
      padding: 1.1em;

      > div {
        margin-bottom: 2em;
      }
    }

    &-right {
      width: 50%;
      flex: 1 1 auto;
      margin-bottom: 12px;
    }
  }
}
