
@import "../../mixins";

.ExtraButtons {
  .popover-trigger {
    position: static;
    padding: 10px;
    margin: -10px;

    &:hover .svg-inline--fa {
      color: var(--text);
    }
  }

  .popover-trigger-button {
    /* override of popover internal stuff */
    width: auto;

    @include unfocused-style {
      .focus-marker {
        visibility: hidden;
      }
    }

    @include focused-style {
      .focus-marker {
        visibility: visible;
      }
    }
  }
}
