
.NavigationEntry.menu-item {
  --__line-height: 2.5em;
  --__horizontal-gap: 0.5em;
  --__vertical-gap: 0.4em;

  padding: 0;
  display: flex;
  align-items: baseline;

  &[aria-expanded] {
    padding-right: var(--__horizontal-gap);
  }

  .main-link {
    line-height: var(--__line-height);
    box-sizing: border-box;
    flex: 1;
    padding: var(--__vertical-gap) var(--__horizontal-gap);
  }

  .menu-icon {
    line-height: var(--__line-height);
    padding: 0;
    width: var(--__line-height);
    margin-right: var(--__horizontal-gap);
  }

  .timelines-chevron {
    line-height: var(--__line-height);
    padding: 0;
    width: var(--__line-height);
    margin-right: 0;
  }

  .extra-button {
    line-height: var(--__line-height);
    padding: 0;
    width: var(--__line-height);
    text-align: center;

    &:last-child {
      margin-right: calc(-1 * var(--__horizontal-gap));
    }
  }

  .badge {
    margin: 0 var(--__horizontal-gap);
  }
}
