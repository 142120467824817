
.MobileNav {
  z-index: var(--ZI_navbar);

  .mobile-nav {
    display: grid;
    line-height: var(--navbar-height);
    grid-template-rows: var(--navbar-height);
    grid-template-columns: 2fr auto;
    width: 100%;
    box-sizing: border-box;

    a {
      color: var(--link);
    }
  }

  .mobile-inner-nav {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .mobile-nav-button {
    display: inline-block;
    text-align: center;
    padding: 0 1em;
    position: relative;
    cursor: pointer;
  }

  .site-name {
    padding: 0 0.3em;
    display: inline-block;
  }

  .item {
    /* moslty just to get rid of extra whitespaces */
    display: flex;
  }

  .mobile-notifications-drawer {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: var(--shadow);
    transition-property: transform;
    transition-duration: 0.25s;
    transform: translateX(0);
    z-index: var(--ZI_navbar);
    -webkit-overflow-scrolling: touch;
    background: var(--background);

    &.-closed {
      transform: translateX(100%);
      box-shadow: none;
    }
  }

  .mobile-notifications-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: calc(var(--ZI_navbar) + 100);
    width: 100%;
    height: 3.5em;
    line-height: 3.5em;
    position: absolute;
    box-shadow: var(--shadow);

    .spacer {
      flex: 1;
    }

    .title {
      font-size: 1.3em;
      margin-left: 0.6em;
    }
  }

  .pins {
    flex: 1;

    .pinned-item {
      flex-grow: 1;
    }
  }

  .mobile-notifications {
    margin-top: 3.5em;
    width: 100vw;
    height: calc(100vh - var(--navbar-height));
    overflow-x: hidden;
    overflow-y: scroll;

    .notifications {
      padding: 0;
      border-radius: 0;
      box-shadow: none;

      .panel {
        border-radius: 0;
        margin: 0;
        box-shadow: none;
      }

      .panel::after {
        border-radius: 0;
      }

      .panel .panel-heading {
        border-radius: 0;
        box-shadow: none;
      }
    }
  }

  .confirm-modal.dark-overlay {
    &::before {
      z-index: 3000;
    }

    .dialog-modal.panel {
      z-index: 3001;
    }
  }
}

