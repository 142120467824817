
.Avatar {
  --_avatarShadowBox: var(--shadow);
  --_avatarShadowFilter: var(--shadowFilter);
  --_avatarShadowInset: var(--shadowInset);
  --_still-image-label-visibility: hidden;

  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;

  &.-compact {
    width: 32px;
    height: 32px;
    border-radius: var(--roundness);
  }

  .avatar {
    width: 100%;
    height: 100%;
    box-shadow: var(--_avatarShadowBox);
    border-radius: var(--roundness);

    &.-better-shadow {
      box-shadow: var(--_avatarShadowInset);
      filter: var(--_avatarShadowFilter);
    }

    &.-animated::before {
      display: none;
    }

    &.-compact {
      border-radius: var(--roundness);
    }

    &.-placeholder {
      background-color: var(--background);
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  .actor-type-indicator {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: -0.2em;
    padding: 0.2em;
    background: rgb(127 127 127 / 50%);
    color: #fff;
    border-radius: var(--roundness);
  }
}
